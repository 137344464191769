import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsappSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const pricingInfo = {
  monthly: {
    id: "monthly",
    plans: [
      {
        price: 390,
        features: [
          "1 Avalição Postural",
          "1 Avaliação de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
      {
        price: 780,
        features: [
          "1 Avalição Postural",
          "1 Avaliação de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },

      {
        price: 1080,
        features: [
          "1 Avalição Postural",
          "1 Avaliação de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
    ],
  },
  semiannually: {
    id: "semiannually",
    plans: [
      {
        price: 375,
        features: [
          "2 Avalições Posturais",
          "2 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
      {
        price: 750,
        features: [
          "2 Avalições Posturais",
          "2 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },

      {
        price: 1050,
        features: [
          "2 Avalições Posturais",
          "2 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
    ],
  },
  annually: {
    id: "annually",
    plans: [
      {
        price: 360,
        features: [
          "3 Avalições Posturais",
          "3 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
      {
        price: 720,
        features: [
          "3 Avalições Posturais",
          "3 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
      {
        price: 1030,
        features: [
          "3 Avalições Posturais",
          "3 Avaliações de Bioimpedância",
          "Treinos Personalizados no Aplicativo",
          "Camiseta Personalizada",
          "Garrafa Personalizada",
        ],
      },
    ],
  },
};

const DarkFeature = ({ text }) => (
  <li className="flex items-center py-3">
    <img className="h-5 check-white" src="/check.svg" />
    <span className="text-white ml-4 text-sm font-medium">{text}</span>
  </li>
);

const LightFeature = ({ text }) => (
  <li class="flex items-center py-3">
    <img class="h-5" src="/check.svg" />
    <span class="text-logo-gray ml-4 text-sm font-medium">{text}</span>
  </li>
);

const ButtonsOptions = ({ options, onSelect, selected }) => {
  return options.map((o) => (
    <Button
      key={o.id}
      variant={o.id === selected ? "gradient" : "outlined"}
      onClick={() => onSelect(o.id)}
      size="sm"
    >
      {o.label}
    </Button>
  ));
};

const bottleOpts = [
  {
    id: "black",
    label: "Garrafa Preta",
  },
  {
    id: "white",
    label: "Garrafa Branca",
  },
];

const shirtOpts = [
  {
    id: "black-traditional",
    label: "Tradicional Preta",
  },
  {
    id: "black-baby",
    label: "Baby Look Preta",
  },
  {
    id: "white-traditional",
    label: "Tradicional Branca",
  },
  {
    id: "white-baby",
    label: "Baby Look Branca",
  },
];

const shirtSizes = [
  {
    id: "P",
    label: "P",
  },
  {
    id: "M",
    label: "M",
  },
  {
    id: "G",
    label: "G",
  },
  {
    id: "GG",
    label: "GG",
  },
];

function App() {
  const pricingSectionRef = useRef();
  const [animate, setAnimate] = useState(false);
  const [giftsModalOpen, setGiftsModalOpen] = useState(false);
  const [selectedShirt, setSelectedShirt] = useState(null);
  const [shirtSize, setShirtSize] = useState(null);
  const [selectedBottle, setSelectedBottle] = useState(null);
  const [frequecy, setFrequency] = useState(null);
  const [selectedRecurrence, setSelectedRecurrence] = useState(
    pricingInfo.annually
  );

  const handleSelectRecurrence = (recurrence) => {
    setAnimate(true);
    setTimeout(() => setSelectedRecurrence(recurrence), 250);
    pricingSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (animate) setAnimate(false);
  }, [animate]);

  const handleSelectFrequency = (freq) => {
    setFrequency(freq);
    setGiftsModalOpen(true);
  };

  const handleContinue = () => {
    const text = `Olá, tenho interesse no plano *${
      { annually: "anual", semiannually: "semestral", monthly: "mensal" }[
        selectedRecurrence.id
      ]
    }* de *${
      { one: "uma", two: "duas", three: "três" }[frequecy]
    } aulas semanais*.
Garrafa: *${bottleOpts.find(({ id }) => id === selectedBottle).label}*
Camiseta: *${
      shirtOpts.find(({ id }) => id === selectedShirt).label
    } - ${shirtSize}*`;
    const phone = "555496325657";
    const url = new URL(phone, `https://wa.me/`);
    url.searchParams.set("text", text);
    window.open(url);
    setGiftsModalOpen(false);
  };

  return (
    <main>
      <Dialog open={giftsModalOpen}>
        <DialogHeader>
          Selecione seus brindes
          <Button
            onClick={() => setGiftsModalOpen(false)}
            size="sm"
            variant="text"
            className="!absolute right-2 top-2 text-xl"
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </DialogHeader>
        <DialogBody>
          <p className="text-logo-gray">Camiseta</p>
          <div className="flex flex-wrap gap-2 mb-2">
            <ButtonsOptions
              selected={shirtSize}
              onSelect={setShirtSize}
              options={shirtSizes}
            />
          </div>
          <div className="flex flex-wrap gap-2">
            <ButtonsOptions
              selected={selectedShirt}
              onSelect={setSelectedShirt}
              options={shirtOpts}
            />
          </div>
          <br />
          <p className="text-logo-gray">Garrafa</p>
          <div className="flex flex-wrap gap-2">
            <ButtonsOptions
              selected={selectedBottle}
              onSelect={setSelectedBottle}
              options={bottleOpts}
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            disabled={!selectedBottle || !selectedShirt || !shirtSize}
            fullWidth
            onClick={handleContinue}
          >
            Continuar
          </Button>
        </DialogFooter>
      </Dialog>
      <div className="absolute z-10 text-gray-200 px-3 py-2 right-0 top-0 text-5xl text-opacity-60">
        <a href="https://www.instagram.com/guilhermelazaretti/">
          <FontAwesomeIcon
            icon={faInstagramSquare}
            className="cursor-pointer"
          />
        </a>
        &nbsp;
        <a href="https://wa.me/555496325657">
          <FontAwesomeIcon icon={faWhatsappSquare} className="cursor-pointer" />
        </a>
      </div>
      <div>
        <div className="relative bg-black pb-24">
          <div
            style={{ backgroundImage: "url(/background.jpeg)" }}
            className="main-bg absolute top-0 left-0 w-full h-full"
          ></div>
          <div className="relative mx-auto max-w-2xl px-6 pt-16 text-center sm:pt-32 lg:max-w-7xl lg:px-8 flex flex-col items-center">
            <img
              className="main-logo hidden lg:block w-full -mb-40 -mt-52"
              src="/logo-horizontal.svg"
            />
            <img
              className="main-logo lg:hidden max-w-full -mx-20 -my-14"
              src="/logo-vertical.svg"
            />
            <p className="mt-4 text-3xl text-gray-100">
              Conheça nossos planos e escolha o melhor para sua saúde!
            </p>
          </div>

          <h3
            id="plans-header"
            ref={pricingSectionRef}
            className="relative text-center text-white pt-6 mt-6 sm:mt-10 text-xl font-semibold"
          >
            Planos
          </h3>
          <div className="mt-2 relative flex justify-center">
            <div
              className="flex rounded-lg bg-logo-gray p-0.5"
              id="recurrence-btns"
            >
              <button
                onClick={() => handleSelectRecurrence(pricingInfo.monthly)}
                id="btn-monthly"
                data-recurrence="monthly"
                type="button"
                className={[
                  "transition-all duration-250 relative ml-0.5 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium focus:z-10 outline-none",
                  selectedRecurrence?.id === "monthly"
                    ? "bg-white text-logo-gray hover:bg-gray-50"
                    : "text-gray-200 hover:bg-gray-800",
                ].join(" ")}
              >
                Mensais
              </button>
              <button
                onClick={() => handleSelectRecurrence(pricingInfo.semiannually)}
                id="btn-semiannually"
                data-recurrence="semiannually"
                type="button"
                className={[
                  "transition-all duration-500 relative ml-0.5 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium focus:z-10 outline-none",
                  selectedRecurrence?.id === "semiannually"
                    ? "bg-white text-logo-gray hover:bg-gray-50"
                    : "text-gray-200 hover:bg-gray-800",
                ].join(" ")}
              >
                Semestrais
              </button>
              <button
                onClick={() => handleSelectRecurrence(pricingInfo.annually)}
                id="btn-annually"
                data-recurrence="annually"
                type="button"
                className={[
                  "transition-all duration-500 relative ml-0.5 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium focus:z-10 outline-none",
                  selectedRecurrence?.id === "annually"
                    ? "bg-white text-logo-gray hover:bg-gray-50"
                    : "text-gray-200 hover:bg-gray-800",
                ].join(" ")}
              >
                Anuais
              </button>
            </div>
          </div>

          <div
            id="cards-wrapper"
            className={[
              "relative mx-auto mt-8 max-w-2xl px-6 pb-8 sm:mt-12 lg:max-w-7xl lg:px-8 lg:pb-0",
              !animate && "animate-transition",
            ].join(" ")}
          >
            <div
              aria-hidden="true"
              className="absolute inset-0 top-4 bottom-6 left-8 right-8 hidden rounded-tl-lg rounded-lg bg-logo-gray lg:block"
            ></div>

            <div className="relative space-y-6 grid lg:grid-cols-3 lg:space-y-0">
              <div className="order-1 bg-logo-gray lg:bg-transparent pt-6 px-6 lg:pb-10 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 className="text-white text-base font-semibold">
                    Uma aula semanal
                  </h3>
                  <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div className="mt-3 flex items-end">
                      <p
                        id="one-class-monthly-cost"
                        className="text-white text-4xl font-bold tracking-tight"
                      >
                        R$ {selectedRecurrence?.plans[0].price},00
                      </p>
                      <div className="ml-2">
                        <p className="text-white text-sm mb-1">/ mês</p>
                      </div>
                    </div>
                    <a
                      id="one-class-contact"
                      onClick={() => handleSelectFrequency("one")}
                      // href="https://wa.me/555496325657?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20mensal%20de%20duas%20aulas%20semanais."
                      className="bg-white text-logo-gray hover:bg-gray-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                    >
                      Contate-nos
                    </a>
                  </div>
                </div>
                <ul
                  id="one-class-features"
                  role="list"
                  className="border-gray-500 divide-gray-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0"
                >
                  {selectedRecurrence?.plans[0].features?.map((f) => (
                    <DarkFeature text={f} />
                  ))}
                </ul>
              </div>

              <div className="lg:order-2 order-3 bg-white ring-2 ring-gray-700 shadow-md pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 className="text-logo-gray text-base font-semibold">
                    Três aulas semanais
                  </h3>
                  <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div className="mt-3 flex items-end">
                      <p
                        id="three-class-monthly-cost"
                        className="text-logo-gray text-4xl font-bold tracking-tight"
                      >
                        R$ {selectedRecurrence?.plans[2].price},00
                      </p>
                      <div className="ml-2">
                        <p className="text-logo-gray text-sm mb-1">/ mês</p>
                      </div>
                    </div>
                    <a
                      id="three-class-contact"
                      onClick={() => handleSelectFrequency("three")}
                      // href="https://wa.me/555496325657?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20mensal%20de%20tres%20ou%20mais%20aulas%20semanais."
                      className="bg-logo-gray text-white hover:bg-gray-800 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                    >
                      Contate-nos
                    </a>
                  </div>
                </div>
                <ul
                  id="three-class-features"
                  role="list"
                  className="border-gray-200 divide-gray-200 mt-7 border-t divide-y lg:border-t-0"
                >
                  {selectedRecurrence?.plans[2].features?.map((f) => (
                    <LightFeature text={f} />
                  ))}
                </ul>
              </div>

              <div className="lg:order-3 order-2 bg-logo-gray lg:bg-transparent pt-6 px-6 lg:pb-10 pb-3 rounded-lg lg:px-8 lg:pt-12">
                <div>
                  <h3 className="text-white text-base font-semibold">
                    Duas aulas semanais
                  </h3>
                  <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div className="mt-3 flex items-end">
                      <p
                        id="two-class-monthly-cost"
                        className="text-white text-4xl font-bold tracking-tight"
                      >
                        R$ {selectedRecurrence?.plans[1].price},00
                      </p>
                      <div className="ml-2">
                        <p className="text-white text-sm mb-1">/ mês</p>
                      </div>
                    </div>
                    <a
                      id="two-class-contact"
                      onClick={() => handleSelectFrequency("two")}
                      // href="https://wa.me/555496325657?text=Ol%C3%A1!%20Tenho%20interesse%20no%20plano%20mensal%20de%20uma%20aula%20semanal."
                      className="bg-white text-logo-gray hover:bg-gray-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                    >
                      Contate-nos
                    </a>
                  </div>
                </div>
                <ul
                  id="two-class-features"
                  role="list"
                  className="border-gray-500 divide-gray-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0"
                >
                  {selectedRecurrence?.plans[1].features?.map((f) => (
                    <DarkFeature text={f} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
